<script setup lang="ts">

import { watch } from 'vue'
import TheIcon from '../TheIcon.vue'

type Props = {
    show: boolean,
    hiddenCloseIcon?: boolean,
    class?: string,
    iconId?: string,
    iconStroke?: string,
    isDisabled?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    iconId: 'close-icon-2',
    class: '',
    iconStroke: '#4A5268',
    isDisabled: false
})
const emit = defineEmits(['close'])

watch(() => props.show, (show) => {
    show
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open')
})

</script>

<template>
    <Teleport to="body" :disabled="isDisabled">
        <!-- <div :class="[ props.class]"> -->
        <Transition name="fade" appear>
            <div
                v-if="show"
                class="modal fade d-block"
                :class="{'show': show}"
                style="z-index: 1100;"
                @click.self="$emit('close')"
            >
                <div class="modal-dialog modal-dialog-centered modal-lg">
                    <!-- overflow-hidden -->
                    <div
                    
                        class="modal-content container"
                    >
                        <div v-if="$slots.header" class="modal-header">
                            <slot name="header" />
                        </div>

                        <div class="modal-body row p-0">
                            <slot />
                        </div>

                        <div v-if="$slots.footer" class="modal-footer">
                            <slot name="footer" />
                        </div>

                        <div
                            v-if="!hiddenCloseIcon"
                            class="d-flex justify-content-end position-absolute right-0 top-0 pr-2 pt-2 pr-md-5 pt-md-4"
                        >
                            <button
                                type="button"
                                class="close modal-close-btn"
                                data-dismiss="modal"
                                aria-label="Close"
                                @click="emit('close')"
                            >
                                <TheIcon    
                                    :icon-id="iconId"
                                    width="24"
                                    height="24"
                                    stroke="#4A5268"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
        <!-- </div> -->
    </Teleport>
</template>

<style scoped lang="scss">
.modal {
    .modal-dialog {
        max-width: fit-content;
    }

    &-close-btn {
        outline: none;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .15s ease;

    .modal-dialog {
        transition: transform 0.3s ease-out;
    }
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;

    .modal-dialog {
        transition: transform 0.3s ease-out;
        transform: translate(0, -50px);
    }
}
</style>