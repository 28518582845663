<script setup lang="ts">
import { computed, onMounted, Ref, ref, watch } from 'vue'
import { RoomType } from '@/Pages/the-home/enums/Search'
import TheModal from '@/Components/the-modal/TheModal.vue'
import DateRangePicker from '@/Components/DateRangePicker.vue'
import dayjs from 'dayjs'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { router } from '@inertiajs/vue3'

import { vOnClickOutside } from '@vueuse/components'
import RoomTypeSwitcher from '@/Components/RoomTypeSwitcher.vue'
import SearchCounter from '@/Components/SearchCounter.vue'
import { useLocationSearch } from '@/Composables/locationSearch'
import AutocompleteSuggestions from '@/Components/AutocompleteSuggestions.vue'

type Props = {
    searchPlaceholder?: string
}

withDefaults(defineProps<Props>(), {
    searchPlaceholder: 'Where are you traveling?'
})

const guests = ref(1)
const roomType: Ref<RoomType> = ref(RoomType.DORM)
const rooms = ref(1)
const skipDate = ref(false)
const isRoomsQuantityVisible = ref(false)
const isDatepickerVisible = ref(false)
const dateRange: Ref<string[]> = ref([dayjs().format('YYYY-MM-DD'), dayjs().add(2, 'day').format('YYYY-MM-DD')])
const searchInputRef: Ref<HTMLInputElement | null> = ref(null)
const currency = ref('USD')

const {
    isOpened,
    search,
    query,
    url,
    autosuggestionResults,
    autosuggestionResultsVisible,
    isSearchInputValid,
    handleSearchInputClick,
    selectPlace,
    isLoadingSuggestions,
    getFirstSearchQuery,
    getSuggestions
} = useLocationSearch()

onMounted(async () => {
    await getSuggestions()
    isOpened.value = true
    autosuggestionResultsVisible.value = true
    searchInputRef.value?.focus()
})

watch(roomType, newType => {
    isRoomsQuantityVisible.value = newType === RoomType.PRIVATE
})


const startDate = computed(() => dayjs(dateRange.value[0]).format('YYYY-MM-DD'))
const endDate = computed(() => dayjs(dateRange.value[1]).format('YYYY-MM-DD'))

const inputDateRange = computed(() => `${ dayjs(dateRange.value[0]).format('D MMM') } - ${ dayjs(dateRange.value[1]).format('D MMM') }`)

const searchPayload = computed(() => ({
    searchOptions: {
        startDate: startDate.value,
        endDate: endDate.value,
        roomType: roomType.value,
        people: guests.value,
        rooms: rooms.value,
        groupType: '',
        groupAgeRanges: [],
        currency: currency.value,
        skipDate: skipDate.value
    }
}))

function submit (): void {
    if (!query.value) {
        isSearchInputValid.value = false
        searchInputRef.value?.focus()
        return
    }

    const currencyForServer = localStorage.getItem('currencyForServer')

    if (currencyForServer) {
        currency.value = currencyForServer
    }

    router.post(url.value, searchPayload.value)
}

function onSkipDates (): void {
    isDatepickerVisible.value = false
    skipDate.value = true

    submit()
}

</script>

<template>
    <div class="home-hero-search">
        <div id="heroSearch" :class="{ heroSearchSmall: !isOpened }">
            <div id="date-search-container" class="search-bar-items">
                <div id="locationHero" class="search-bar-item input-icon input-search">
                    <input
                        ref="searchInputRef"
                        v-model.trim="search"
                        v-on-click-outside="() => autosuggestionResultsVisible = false"
                        type="text"
                        class="websiteIndexSearch form-control"
                        :class="{ 'is-invalid': !isSearchInputValid }"
                        :placeholder="searchPlaceholder"
                        required
                        @click="handleSearchInputClick"
                        @keyup.enter="isDatepickerVisible = true; autosuggestionResultsVisible = false, getFirstSearchQuery()"
                    >

                    <div v-if="isLoadingSuggestions" class="spinner-wrap mt-3 ml-5">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <AutocompleteSuggestions
                        v-show="!isLoadingSuggestions && autosuggestionResultsVisible"
                        :suggestions="autosuggestionResults"
                        @select-place="selectPlace($event); isDatepickerVisible = true"
                    />
                </div>

                <div class="search-form-index">
                    <div class="bookingSearchForm d-flex flex-row bk-search bk-search--index justify-content-between">
                        <div class="my-lg-5">
                            <label class="pre-title cl-subtext" for="searchDate">Add dates</label>
                            <div class="datepicker-container datepicker-container-left input-icon input-calendar">
                                <input
                                    id="searchDateHeroTitle"
                                    type="text"
                                    class="form-control cursor-pointer"
                                    :value="inputDateRange"
                                    @click="isDatepickerVisible = true"
                                >
                            </div>
                        </div>

                        <div class="my-lg-5">
                            <RoomTypeSwitcher v-model="roomType" class="cl-subtext" />
                        </div>

                        <div v-if="isRoomsQuantityVisible" class="my-lg-5 bookingSearchRooms">
                            <SearchCounter v-model="rooms" label="Rooms" class="cl-subtext" />
                        </div>

                        <div class="my-lg-5">
                            <SearchCounter v-model="guests" label="Guests" class="cl-subtext" />
                        </div>
                    </div>
                </div>

                <div class="search-bar-item">
                    <button
                        type="button"
                        class="btn bg-primary text-white btn-block mt-2 mt-sm-0 text-nowrap"
                        @click.stop="submit"
                    >
                        <FontAwesomeIcon class="mr-2" :icon="faSearch" />

                        <span>Search</span>
                    </button>
                </div>
            </div>
        </div>

        <TheModal
            :show="isDatepickerVisible"
            hidden-close-icon
            @close="isDatepickerVisible = false"
        >
            <div class="picker-wrapper">
                <DateRangePicker v-model="dateRange" @dates-selected="isDatepickerVisible = false" />
            </div>

            <template #footer>
                <button
                    type="button"
                    class="btn btn-sm btn-primary mr-auto"
                    @click="onSkipDates"
                >
                    skip dates
                </button>
            </template>
        </TheModal>
    </div>
</template>

<style lang="scss" scoped>
.autocomplete-suggestions {
    position: absolute;
    max-height: 300px;
    z-index: 1100;
    width: 876px;
}

.autocomplete-suggestion {
    &:hover {
        background-color: #ECEFF4;
    }
}

.picker-wrapper {
    padding: 16px 15px;
}
</style>